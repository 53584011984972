// eslint-disable-next-line no-restricted-imports
import ReactMarkdown, { Options } from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';

export default ({ children, components, ...restProps }: Options) => (
  <ReactMarkdown
    components={{
      ...components,
      a: ({ node, ...props }) => (
        <a {...props} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      ),
      code({ node, children, className, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return match ? (
          // @ts-ignore - Ignore the type incompatibility error here
          <SyntaxHighlighter {...props} children={String(children).replace(/\n$/, '')} language={match[1]} />
        ) : (
          <code {...props} className={className}>
            {children}
          </code>
        );
      },
    }}
    {...restProps}
  >
    {children}
  </ReactMarkdown>
);
