import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import ErrorBoundaryAdvanced from './ErrorBoundaryAdvanced';
import PagesLayout from 'layout/Pages';
import Loadable from 'components/Loadable';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    {
      path: '/',
      errorElement: <ErrorBoundaryAdvanced />,
      children: [
        ...MainRoutes,
        ...LoginRoutes,
        {
          path: '/maintenance',
          element: <PagesLayout />,
          children: [
            {
              path: '404',
              element: <MaintenanceError />,
            },
            {
              path: '500',
              element: <MaintenanceError500 />,
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />,
            },
            {
              path: 'coming-soon',
              element: <MaintenanceComingSoon />,
            },
          ],
        },
      ],
    },
  ],
  { basename: import.meta.env.VITE_APP_BASE_NAME }
);

export default router;
