// material-ui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// project import
import IconButton from 'components/@extended/IconButton';

// assets
import EuroOutlined from '@ant-design/icons/EuroCircleOutlined';
import { Link } from 'react-router-dom';

export default function BuyCredits() {
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Buy Credits">
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: 'transparent' }}
          aria-label="buy credits"
          component={Link} // Use Link component for routing
          to="/credits" // Set the path you want to navigate to
        >
          <EuroOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
