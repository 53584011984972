// third-party
import { FormattedMessage } from 'react-intl';

// assets
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { HomeOutlined, TeamOutlined, BankOutlined, ContainerOutlined };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pageHome = {
  id: 'home',
  title: <FormattedMessage id="home" />,
  type: 'item',
  icon: icons.HomeOutlined,
  url: '/',
};

const usersPage = {
  id: 'user',
  title: <FormattedMessage id="users" />,
  type: 'item',
  icon: icons.TeamOutlined,
  url: '/user',
};

const companiesPage = {
  id: 'company',
  title: <FormattedMessage id="companies" />,
  type: 'item',
  icon: icons.BankOutlined,
  url: '/company',
};

const pentestsPage = {
  id: 'pentest',
  title: <FormattedMessage id="pentests" />,
  type: 'item',
  icon: icons.ContainerOutlined,
  url: '/pentest',
};

const pentestersPage = {
  id: 'pentester',
  title: <FormattedMessage id="pentesters" />,
  type: 'item',
  icon: icons.TeamOutlined,
  url: '/pentester',
};

const adminPages: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="pages" />,
  type: 'group',
  children: [pageHome, pentestsPage, companiesPage, usersPage, pentestersPage],
};

const pentesterPages: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="pages" />,
  type: 'group',
  children: [pageHome, pentestsPage],
};

const customerPages: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="pages" />,
  type: 'group',
  children: [pageHome, pentestsPage, usersPage],
};

export default {
  adminPages,
  pentesterPages,
  customerPages,
};
