import React, { useState, createContext } from 'react';

// project import
import Loader from 'components/Loader';

export type LoaderContextType = {
  loading: boolean;
  setLoading: (a: boolean) => void;
};

const LoaderContext = createContext<LoaderContextType>({ loading: false, setLoading: () => {} });

export const LoaderProvider = ({ children }: { children: React.ReactElement }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {loading && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
