import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';

const PlatformHomePage = Loadable(lazy(() => import('pages/home/home')));
const CompanyNewPage = Loadable(lazy(() => import('pages/company/new')));
const CompanyListPage = Loadable(lazy(() => import('pages/company/list')));
const UserNewPage = Loadable(lazy(() => import('pages/user/new')));
const UserListPage = Loadable(lazy(() => import('pages/user/list')));
const PentestDraftPage = Loadable(lazy(() => import('pages/pentest/draft')));
const PentestListPage = Loadable(lazy(() => import('pages/pentest/list')));
const PentestPage = Loadable(lazy(() => import('pages/pentest')));
const PentestTabDetails = Loadable(lazy(() => import('pages/pentest/TabDetails')));
const PentestTabFindings = Loadable(lazy(() => import('pages/pentest/TabFindings')));
const PentestTabEdit = Loadable(lazy(() => import('pages/pentest/TabEdit')));
const CreditsPage = Loadable(lazy(() => import('pages/credits')));
const CreditsSuccessPage = Loadable(lazy(() => import('pages/credits/success')));
const PentesterListPage = Loadable(lazy(() => import('pages/pentester/list')));
const PentesterNewPage = Loadable(lazy(() => import('pages/pentester/new')));
const FindingEditPage = Loadable(lazy(() => import('pages/finding/edit')));
const FindingViewPage = Loadable(lazy(() => import('pages/finding/view')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <PlatformHomePage />,
      },
      {
        path: 'credits',
        children: [
          {
            path: '',
            element: <CreditsPage />,
          },
          {
            path: 'success',
            element: <CreditsSuccessPage />,
          },
        ],
      },
      {
        path: 'company',
        children: [
          {
            path: '',
            element: <CompanyListPage />,
          },
          {
            path: 'new',
            element: <CompanyNewPage />,
          },
        ],
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: <UserListPage />,
          },
          {
            path: 'new',
            element: <UserNewPage />,
          },
        ],
      },
      {
        path: 'pentester',
        children: [
          {
            path: '',
            element: <PentesterListPage />,
          },
          {
            path: 'new',
            element: <PentesterNewPage />,
          },
        ],
      },
      {
        path: 'pentest',
        children: [
          {
            path: '',
            element: <PentestListPage />,
          },
          {
            path: ':pentestId/finding',
            children: [
              {
                path: '',
                element: <FindingEditPage />,
              },
              {
                path: ':findingId',
                element: <FindingViewPage />,
              },
              {
                path: ':findingId/edit',
                element: <FindingEditPage />,
              },
            ],
          },
          {
            path: 'draft',
            element: <PentestDraftPage />,
          },
          {
            path: 'draft/:id',
            element: <PentestDraftPage />,
          },
          {
            path: ':id',
            element: <PentestPage />,
            children: [
              {
                path: 'details',
                element: <PentestTabDetails />,
              },
              {
                path: 'findings',
                element: <PentestTabFindings />,
              },
              {
                path: 'edit',
                element: <PentestTabEdit />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default MainRoutes;
