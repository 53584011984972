import { lazy } from 'react';

// project import
import AuthLayout from 'layout/Auth';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = [
  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <AuthLogin />,
      },
      {
        path: 'forgot-password',
        element: <AuthForgotPassword />,
      },
      {
        path: 'activate-account/:code',
        element: <AuthResetPassword activate />,
      },
      {
        path: 'reset-password/:code',
        element: <AuthResetPassword />,
      },
      {
        path: 'check-mail',
        element: <AuthCheckMail />,
      },
    ],
  },
];

export default LoginRoutes;
